import {
  CustomDatePicker,
  CustomDropdown,
  CustomDropdownOption,
  GENERIC_ERROR_MESSAGE,
  gridStyle,
} from "@ivueit/vue-engine";
import MDBox from "components/MDBox";
import { Moment } from "moment";
import { useCallback, useEffect, useReducer, useState } from "react";
import Icon from "@mdi/react";
import defaultColumnDefinition from "assets/jsons/invoicesGridColumns.json";
import {
  availableStatusTypes,
  availableTransactionTypes,
  pinnedColumn,
} from "../utils/constants";
import { mdiEye, mdiDownload, mdiFilterOff } from "@mdi/js";
import { Card, IconButton, Link, Tooltip, Typography } from "@mui/material";
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridRenderCellParams,
  GridRowClassNameParams,
} from "@mui/x-data-grid";
import { DataGridPremium, useGridApiRef } from "@mui/x-data-grid-premium";
import { tooltipStyles } from "pages/dashboard/home/site-lists/styles/site_list_style";
import "../styles/invoices-grid-styles.css";
import { useNavigate } from "react-router";
import { DEFAULT_PAGE_SIZE, RoutePath } from "../../../../constants";
import {
  gridContentStyle,
  gridContentWithEllipsis,
  gridCardStyle,
} from "../styles/invoicesStyles";
import { StyledButtonWithIcon } from "pages/dashboard/home/vues/vue-grid/components/VueActionBar";
import {
  InvoiceInfo,
  InvoiceMetaData,
  InvoicesGridData,
  defaultInvoicesMetaData,
} from "../utils/interfaces";
import { WebServiceStatus } from "utils/services/AppUrls";
import { getInvoiceByID, getInvoicesForMyCompany } from "../services/service";
import CustomSnackbar, {
  CustomSnackbarContent,
} from "pages/components/CustomSnackbar";
import {
  downloadInvoiceAsPDF,
  getAgingInDays,
  getInvoiceGridData,
} from "../helpers/helper_methods";
import { filterDateToNanoSecondsString } from "pages/dashboard/home/vues/vue-grid/helpers/helper_methods";
import {
  InvoicePageActionType,
  defaultInvoicePageSummaryState,
  invoicesPageSummaryReducer,
} from "../utils/reducer";

export const InvoicesDataGrid = () => {
  const gridRef = useGridApiRef();
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const columnData: GridColDef[] = defaultColumnDefinition;
  const [rows, setRows] = useState<InvoicesGridData[]>([]);
  const [meta, setMeta] = useState<InvoiceMetaData>(defaultInvoicesMetaData);
  const [snackbar, setSnackbar] = useState<CustomSnackbarContent | null>(null);
  const [shouldResetDate, setShouldResetDate] = useState<boolean>(false);
  const [shouldResetValue, setShouldResetValue] = useState<boolean>(false);
  const [invoiceState, dispatchInvoicesFetchAction] = useReducer(
    invoicesPageSummaryReducer,
    defaultInvoicePageSummaryState
  );
  const { startDate, endDate, status, transactionType } = invoiceState;
  const [dateError, setDateError] = useState({
    startDate: "",
    endDate: "",
  });
  const hasValidDateParams =
    !dateError.startDate && !dateError.endDate && startDate && endDate;

  ///// DATE PICKERS /////

  const handleStartDateChange = (newDate: Moment | null) => {
    setShouldResetDate(false);
    const error = newDate
      ? (endDate && newDate && newDate > endDate) || !newDate.isValid()
        ? "Invalid start date."
        : ""
      : "";
    setDateError((prev) => ({
      startDate: error,
      endDate: "",
    }));
    const date = newDate !== null && newDate.isValid() ? newDate : null;
    dispatchInvoicesFetchAction({
      type: InvoicePageActionType.startDateChanged,
      payload: {
        ...invoiceState,
        startDate: date,
      },
    });
  };

  const handleEndDateChange = (newDate: Moment | null) => {
    setShouldResetDate(false);
    const error = newDate
      ? (startDate && startDate > newDate) || !newDate.isValid()
        ? "Invalid end date."
        : ""
      : "";
    setDateError((prev) => ({
      startDate: "",
      endDate: error,
    }));
    const date = newDate !== null && newDate.isValid() ? newDate : null;
    dispatchInvoicesFetchAction({
      type: InvoicePageActionType.endDateChanged,
      payload: {
        ...invoiceState,
        endDate: date,
      },
    });
  };

  const getDatePickers = () => {
    const helperTextStyle = {
      "p.MuiFormHelperText-root": {
        position: "absolute",
        bottom: "-16px",
        left: "5px",
      },
    };
    return (
      <MDBox display="flex">
        <MDBox ml={1} sx={helperTextStyle}>
          <CustomDatePicker
            title="Start Date"
            defaultDate={null}
            minimumDate={startDate}
            onChange={handleStartDateChange}
            shouldResetDate={shouldResetDate}
            errorMessage={dateError.startDate}
          />
        </MDBox>
        <MDBox ml={1} sx={helperTextStyle}>
          <CustomDatePicker
            title="End Date"
            defaultDate={null}
            minimumDate={endDate}
            onChange={handleEndDateChange}
            errorMessage={dateError.endDate}
            shouldResetDate={shouldResetDate}
          />
        </MDBox>
      </MDBox>
    );
  };

  ///// TRANSACTION & STATUS DROP-DOWNS /////

  const handleTransactionDropDownClick = (newValue: string) => {
    dispatchInvoicesFetchAction({
      type: InvoicePageActionType.transactionTypeChanged,
      payload: {
        ...invoiceState,
        transactionType: newValue,
      },
    });
    setShouldResetValue(false);
  };

  const handleStatusDropDownClick = (newValue: string) => {
    dispatchInvoicesFetchAction({
      type: InvoicePageActionType.statusChanged,
      payload: {
        ...invoiceState,
        status: newValue,
      },
    });
    setShouldResetValue(false);
  };

  const getDropDownElements = () => {
    return (
      <MDBox mt="auto" display="none">
        <CustomDropdown
          title={"Transaction"}
          shouldResetValue={shouldResetValue}
          selectedItem={{
            value: transactionType,
            displayTitle: transactionType,
          }}
          availableValues={availableTransactionTypes.map((type) => {
            return {
              value: type,
              displayTitle: type,
            };
          })}
          onChange={(newValue: CustomDropdownOption) => {
            handleTransactionDropDownClick(newValue.value);
          }}
          prefixTitle={true}
          minWidth="175px"
        />
        <CustomDropdown
          title={"Status"}
          shouldResetValue={shouldResetValue}
          selectedItem={{
            value: status,
            displayTitle: status,
          }}
          availableValues={availableStatusTypes.map((type) => {
            return {
              value: type,
              displayTitle: type,
            };
          })}
          onChange={(newValue: CustomDropdownOption) => {
            handleStatusDropDownClick(newValue.value);
          }}
          prefixTitle={true}
          minWidth="175px"
        />
      </MDBox>
    );
  };

  ///// CLEAR ALL FILTER BUTTON /////

  const getClearAllFiltersButton = () => {
    return (
      hasValidDateParams && (
        <MDBox mr="auto" mt="auto" flexShrink="0" pr={1} ml={1}>
          <StyledButtonWithIcon
            iconPath={mdiFilterOff}
            onClick={() => {
              setShouldResetDate(true);
              setShouldResetValue(true);
              dispatchInvoicesFetchAction({
                type: InvoicePageActionType.clearAllFilterClicked,
                payload: defaultInvoicePageSummaryState,
              });
            }}
          >
            {/* // Hard-coded 1 as we have only one filter in this screen */}
            {`Clear All Filters (1)`}
          </StyledButtonWithIcon>
        </MDBox>
      )
    );
  };

  ///// GRID METHODS /////

  const getNoRowsMessage = () => {
    return (
      rows.length === 0 && (
        <MDBox position="relative" top="30%">
          <Typography
            textAlign="center"
            variant="h6"
            sx={{ fontWeight: "500", color: "#939393" }}
          >
            There are no Invoices generated yet
          </Typography>
        </MDBox>
      )
    );
  };

  /// This returns the customized value for each column
  const getFieldBasedValue = (params: GridRenderCellParams) => {
    const { field, value } = params;
    let customizedValue = value;
    if (field === "amount") {
      customizedValue = `$ ${value}`;
    } else if (field === "balance") {
      customizedValue = value === 0 ? "-" : `$ ${value}`;
    } else if (field === "aging") {
      const days = getAgingInDays(params.row.dueDate);
      customizedValue =
        params.row.balance !== 0 && days > 0 ? `${days} days past` : "-";
    } else if (field === "paymentDate") {
      customizedValue = value === "0" ? "-" : value;
    }
    return customizedValue;
  };

  const handleDownloadButtonClick = async (invoiceId: string) => {
    setLoader(true);
    const response = await getInvoiceByID(invoiceId);
    if (response.status === WebServiceStatus.success) {
      const responseData = response.data;
      const dataURL = responseData.data;
      if (dataURL) {
        const hasDownloaded = (await downloadInvoiceAsPDF(
          dataURL,
          invoiceId
        )) as boolean;
        if (hasDownloaded) {
          setSnackbar({
            title: "Success",
            message: "Invoice downloaded successfully",
            isError: false,
          });
        } else {
          setSnackbar({
            title: "Attention!",
            message: "Failed to download the invoice",
            isError: true,
          });
        }
      } else {
        setSnackbar({
          title: "Attention!",
          message: GENERIC_ERROR_MESSAGE,
          isError: true,
        });
      }
    } else {
      setSnackbar({
        title: "Attention!",
        message: `Unable to fetch the invoice data URL : ${
          response.error ?? GENERIC_ERROR_MESSAGE
        }`,
        isError: true,
      });
    }
    setLoader(false);
  };

  const getCustomCell = (params: GridRenderCellParams) => {
    const { field, value } = params;
    const customizedValue = getFieldBasedValue(params);
    const showPayOption = false;
    /// Currently, this is not applied as we don't have an option to let the client do the payment
    const hidePayOption =
      (params.row.status as string).toUpperCase() === "PAID" ||
      (params.row.status as string).toUpperCase() === "CLOSED";
    if (field === "date") {
      return (
        <MDBox className="MuiDataGrid-cellContent" sx={gridContentStyle}>
          {customizedValue.formatUTCNanoSecondsToString("MM/DD/YYYY")}
        </MDBox>
      );
    }
    if (field === "dueDate") {
      return (
        <MDBox display="flex">
          <MDBox className="MuiDataGrid-cellContent" sx={gridContentStyle}>
            {customizedValue.formatUTCNanoSecondsToString("MM/DD/YYYY")}
          </MDBox>
          <MDBox display="flex" width="100px">
            <MDBox className="flexCell">
              {!showPayOption ? (
                <></>
              ) : (
                <Link
                  sx={{ cursor: "pointer", ml: "20px" }}
                  onClick={() => {
                    /// TODO : Navigate to the payment channel - Quickbooks
                  }}
                  variant="button"
                  color="primary"
                  fontWeight="bold"
                >
                  PAY
                </Link>
              )}
            </MDBox>
            <MDBox className="flexCell">
              <IconButton
                sx={{ display: "inline-block !important", ml: "20px" }}
                onClick={(event) => {
                  event.stopPropagation();
                  navigate(`${RoutePath.invoices}/${params.row.id}`, {
                    state: {
                      invoiceId: params.row.id,
                    },
                  });
                }}
              >
                <Icon path={mdiEye} size={0.86} color="#1A73E7" />
              </IconButton>
            </MDBox>
            <MDBox className="flexCell">
              <IconButton
                sx={{ display: "inline-block !important", ml: "20px" }}
                onClick={async (event) => {
                  event.stopPropagation();
                  await handleDownloadButtonClick(params.row.id);
                }}
              >
                <Icon path={mdiDownload} size={0.86} color="#1A73E7" />
              </IconButton>
            </MDBox>
          </MDBox>
        </MDBox>
      );
    }
    if (field === "memo") {
      return (
        <Tooltip
          title={value}
          arrow
          placement="bottom-start"
          componentsProps={{
            tooltip: {
              sx: tooltipStyles,
            },
          }}
        >
          <MDBox className="MuiDataGrid-cellContent">
            <MDBox sx={{ ...gridContentWithEllipsis, maxWidth: "unset" }}>
              {value}
            </MDBox>
          </MDBox>
        </Tooltip>
      );
    }
    return (
      <MDBox className="MuiDataGrid-cellContent" sx={gridContentStyle}>
        <MDBox>{customizedValue}</MDBox>
      </MDBox>
    );
  };

  const columns = columnData.map((data) => {
    data.renderCell = getCustomCell;
    return data;
  });

  const getRowClassName = (params: GridRowClassNameParams<any>): string => {
    const days = getAgingInDays(params.row.dueDate);
    const balance = params.row.balance;
    const hasPaymentOverDue = balance !== 0 && days > 0;
    return hasPaymentOverDue ? "color-danger" : "";
  };

  // Check for the valid start date and end date
  const isValidParameter = () => {
    const isValid =
      (startDate?.isValid() && endDate?.isValid()) ||
      (!startDate?.isValid() && !endDate?.isValid());
    return isValid && !dateError.startDate && !dateError.endDate;
  };

  const generateInvoiceAPIParamter = (
    pageNumber: number,
    pageSize: number
  ): string => {
    var parameterString = `pageSize=${pageSize}&pageNumber=${pageNumber}`;
    if (startDate && endDate) {
      const formattedStartDate = filterDateToNanoSecondsString(startDate);
      const formattedEndDate = filterDateToNanoSecondsString(endDate, true);
      parameterString += `&date.startTime=${formattedStartDate}`;
      parameterString += `&date.endTime=${formattedEndDate}`;
    }
    return parameterString;
  };

  const getParameterString = useCallback(
    (isScrolling: boolean): string => {
      // Default page number will be 1 if not scrolling
      var newPageNumber = isScrolling ? meta.pageNumber + 1 : 1;
      var pageSize = DEFAULT_PAGE_SIZE;
      return generateInvoiceAPIParamter(newPageNumber, pageSize);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [meta.pageNumber, startDate, endDate, transactionType, status]
  );

  const getInvoicesList = useCallback(
    async (isScrolling: boolean) => {
      if (isValidParameter()) {
        const parameters = getParameterString(isScrolling);
        setLoader(true);
        const response = await getInvoicesForMyCompany(parameters);
        var listOfInvoices: InvoicesGridData[] = [];
        if (response.status === WebServiceStatus.success) {
          const {
            invoices,
            meta,
          }: { invoices: InvoiceInfo[]; meta: InvoiceMetaData } = response.data;
          listOfInvoices = invoices.map((invoice) => {
            return getInvoiceGridData(invoice);
          });
          const metaData = {
            pageSize: Number(meta.pageSize),
            pageNumber: Number(meta.pageNumber),
            totalPages: Number(meta.totalPages),
            totalElements: Number(meta.totalElements),
          };
          setMeta(metaData);
        } else {
          setSnackbar({
            title: "Attention!",
            message: response.error ?? GENERIC_ERROR_MESSAGE,
            isError: true,
          });
        }
        if (isScrolling) {
          setRows((prev) => prev.concat(listOfInvoices));
        } else {
          setRows(listOfInvoices);
        }
        setLoader(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getParameterString]
  );

  useEffect(() => {
    getInvoicesList(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate, transactionType, status]);

  return (
    <MDBox>
      <MDBox display="flex">
        {getDatePickers()}
        {getDropDownElements()}
        {getClearAllFiltersButton()}
      </MDBox>
      <Card sx={gridCardStyle}>
        <DataGridPremium
          apiRef={gridRef}
          sx={{
            ...gridStyle,
            ".MuiIconButton-root": {
              lineHeight: "16px",
            },
          }}
          rows={rows}
          columns={columns}
          loading={loader}
          density="compact"
          disableColumnMenu
          disableColumnSorting
          hideFooterRowCount
          hideFooterSelectedRowCount
          keepNonExistentRowsSelected
          getRowClassName={getRowClassName}
          slots={{
            noRowsOverlay: getNoRowsMessage,
          }}
          slotProps={{
            loadingOverlay: {
              variant: "linear-progress",
              noRowsVariant: "linear-progress",
            },
          }}
          initialState={{
            pinnedColumns: {
              left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, ...pinnedColumn],
            },
          }}
        />
      </Card>
      <CustomSnackbar
        snackbarContent={snackbar}
        onClose={() => {
          setSnackbar(null);
        }}
      />
    </MDBox>
  );
};
